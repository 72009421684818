<template>
  <div class="setp_2">
    <div class="title">
      <i class="el-icon-caret-right icon"></i>
      <span class="font26 color33">职业技能等级认定个人申请表</span>
      <i class="el-icon-caret-left icon"></i>
    </div>
    <el-form :model="myForm" ref="myForm">
      <table border="1">
        <tr>
          <td colspan="5" class="title">一、基本资料</td>
        </tr>
        <tr>
          <td class="bg_text">
            <i>*</i>姓名
          </td>
          <td>
            <el-form-item
              prop="student.name"
              :rules="[
                {required: true, message: '请输入姓名', trigger: 'blur'},
              ]"
            >
              <el-input :disabled="true" v-model="myForm.student.name" placeholder="输入姓名"></el-input>
            </el-form-item>
          </td>
          <td class="bg_text">
            <i>*</i>性别
          </td>
          <td>
            <el-form-item
              prop="student.sex"
              :rules="[
                {
                  required: true,
                  message: '请选择性别',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-select :disabled="true" placeholder="选择性别" v-model="myForm.student.sex">
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
                <el-option label="未知" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </td>
          <!-- <td rowspan="4" style="position: relative">
            <span
              @click="showQuestion = !showQuestion"
              class="icon_tip el-icon-info"
              style="
                position: absolute;
                cursor: pointer;
                right: 5px;
                top: 5px;
                color: #2b68d9;
              "
            ></span>
            <div class="card_box" v-if="showQuestion">
              <p>
                * 电子照片用于制作证书，请确保长宽为4cmX3cm免冠证件照；
                支持png、jpg、jpeg、gif格式上传，不超过500K
              </p>
            </div>
            <i style="position: absolute; color: red; left: 10px; top: 5px"
              >*</i
            >
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="#"
              :auto-upload="false"
              :on-change="changeFile"
            >
              <img
                v-if="myForm.studentExamInfo.avatarImageData"
                :src="myForm.studentExamInfo.avatarImageData"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </td>-->
          <!-- <td rowspan="4" style="position: relative"></td> -->
          <td rowspan="4" style="position: relative">
            <!-- <span
                    @click="showQuestion = !showQuestion"
                    class="icon_tip el-icon-info"
                    style="
                      position: absolute;
                      cursor: pointer;
                      right: 5px;
                      top: 5px;
                      color: #2b68d9;
                    "
            ></span>-->
            <!-- <div class="card_box" v-if="showQuestion">
                    <p>
                      * 电子照片用于制作证书，请确保长宽为4cmX3cm免冠证件照；
                      支持png、jpg、jpeg、gif格式上传，不超过500K
                    </p>
            </div>-->
            <i style="position: absolute; color: red; left: 10px; top: 5px">*</i>
            <!-- {{ StudentExamination.avatarUrl }} -->
            <el-upload
              class="my_avatar-uploader"
              :show-file-list="false"
              action="#"
              :auto-upload="false"
              :on-change="changeFile"
            >
              <template slot="tip" v-if="!myForm.studentExamInfo.avatarImageData">
                <p style="font-size: 14px; color: gray">请上传白底，jpg/jpeg格式免冠照，不超过2MB;</p>
              </template>
              <img
                v-if="myForm.studentExamInfo.avatarImageData"
                :src="myForm.studentExamInfo.avatarImageData"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </td>
        </tr>
        <tr>
          <td class="bg_text">
            <i>*</i>出生年月
          </td>
          <td>
            <el-form-item
              prop="student.birthday"
              :rules="[
                {
                  required: true,
                  message: '请选择出生年月',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-date-picker
                :disabled="true"
                type="date"
                placeholder="选择出生年月"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                v-model="myForm.student.birthday"
              ></el-date-picker>
            </el-form-item>
          </td>
          <td class="bg_text">
            <i>*</i>手机号码
          </td>
          <td>
            <el-form-item
              prop="student.mobile"
              :rules="[
                {
                  required: true,
                  min: 11,
                  max: 11,
                  message: '输入11位有效手机号',
                  trigger: ['blur'],
                },
              ]"
            >
              <el-input placeholder="输入手机号码" v-model.trim="myForm.student.mobile" />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">
            <i>*</i>当前最高学历
          </td>
          <td colspan="3">
            <el-form-item
              prop="student.education"
              :rules="[
                {
                  required: true,
                  message: '请选择出生年月',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-select placeholder="请选择学历" style="width: 100%" v-model="myForm.student.education">
                <el-option
                  v-for="item in educateSelectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="title">二、报考信息</td>
        </tr>
        <tr>
          <td class="bg_text">报考职业</td>
          <td>
            <el-form-item>
              <el-input
                disabled
                :value="
                  planInfo.skillCategoryFirstName +
                  '/' +
                  planInfo.skillCategorySecondName
                "
                placeholder="输入报考职业"
              />
            </el-form-item>
          </td>
          <td class="bg_text">报考级别</td>
          <td colspan="2">
            <el-form-item>
              <el-input disabled v-model="myForm.studentExamInfo.profLevel" placeholder="输入报考级别" />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">
            <i>*</i>考试类型
          </td>
          <td>
            <el-form-item
              prop="studentExamInfo.examType"
              :rules="[
                {
                  required: true,
                  message: '请选择考试类型',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-input type="text" disabled v-model="myForm.studentExamInfo.examType"></el-input>
              <!-- <span>{{ myForm.studentExamInfo.examType }}</span> -->
              <!-- <el-select
                style="width: 100%"
                @change="changEexamType"
                v-model="myForm.studentExamInfo.examType"
              >
                <el-option value="正考">正考</el-option>
                <el-option value="补考">补考</el-option>
              </el-select>-->
            </el-form-item>
          </td>
          <td class="bg_text">
            <i>*</i>考试科目
          </td>
          <td colspan="2">
            <p>{{ myForm.studentExamInfo.profSubject }}</p>
          </td>
        </tr>
        <tr>
          <td class="bg_text">考试地点</td>
          <td colspan="4">
            <el-form-item>
              <el-input disabled v-model="myForm.studentExamInfo.examAddress" placeholder="输入考试地点" />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">
            已获职业资格/
            <br />技能等级证书名称
          </td>
          <td colspan="4">
            <el-form-item>
              <el-input v-model="myForm.studentExamInfo.techCert" placeholder="输入已获职业资格/技能等级证书名称" />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">证书等级</td>
          <td>
            <el-form-item>
              <!-- <el-input
                v-model.trim="myForm.studentExamInfo.techCertLevelName"
                placeholder="输入证书等级"
              />-->
              <el-select
                style="width: 100%"
                placeholder="选择证书等级"
                v-model="myForm.studentExamInfo.techCertLevelName"
                clearable
              >
                <el-option value="五级/初级工">五级/初级工</el-option>
                <el-option value="四级/中级工">四级/中级工</el-option>
                <el-option value="三级/高级工">三级/高级工</el-option>
                <el-option value="二级/技师">二级/技师</el-option>
                <el-option value="一级/高级技师">一级/高级技师</el-option>
              </el-select>
            </el-form-item>
          </td>
          <td class="bg_text">获证日期</td>
          <td colspan="2">
            <el-form-item>
              <el-date-picker
                type="date"
                v-model="myForm.studentExamInfo.techCertGainTime"
                placeholder="选择获证日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">证书编号</td>
          <td colspan="4">
            <el-form-item>
              <el-input v-model="myForm.studentExamInfo.techCertNo" placeholder="输入证书编号" />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">
            已获专业技术
            <br />资格（职称）
          </td>
          <td colspan="4">
            <el-form-item>
              <el-input v-model="myForm.studentExamInfo.profCert" placeholder="输入已获专业技术资格（职称）" />
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">等级</td>
          <td>
            <el-form-item>
              <el-input placeholder="输入等级" v-model="myForm.studentExamInfo.profCertLevel" />
            </el-form-item>
          </td>
          <td class="bg_text">获证日期</td>
          <td colspan="2">
            <el-form-item>
              <el-date-picker
                type="date"
                v-model="myForm.studentExamInfo.profCertGainTime"
                placeholder="选择获证日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="bg_text">证书编号</td>
          <td colspan="4">
            <el-form-item>
              <el-input placeholder="输入证书编号" v-model="myForm.studentExamInfo.profCertNo" />
            </el-form-item>
          </td>
        </tr>
        <tr v-if="!myFormOpenP">
          <td class="bg_text">
            <i>*</i>申报条件
          </td>
          <td colspan="4">
            <el-form-item
              prop="studentExamInfo.applyCondition"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-select
                v-model="myForm.studentExamInfo.applyCondition"
                v-if="applications.length != 0"
                :disabled="myFormOpenP"
                placeholder="请选择"
                class="el-input__diatext"
                style="width: 100%"
                @change="selectApplyCondition"
                clearable
              >
                <el-option
                  v-for="item in applications"
                  style="width: 730px"
                  :key="item.id"
                  :label="item.content"
                  :value="item"
                ></el-option>
                <div class="showtips">{{ posttips }}</div>
              </el-select>
              <el-input
                v-else
                :disabled="myFormOpenP"
                v-model="myForm.studentExamInfo.applyCondition"
                @input="inputApplyCondition"
                placeholder="输入申报条件"
              />
            </el-form-item>
          </td>
        </tr>
         <tr v-else>
          <td class="bg_text">
            <i></i>申报条件
          </td>
          <td colspan="4">
            <el-form-item
              prop="studentExamInfo.applyCondition"
            >
              <el-select
                v-model="myForm.studentExamInfo.applyCondition"
                v-if="applications.length != 0"
                :disabled="myFormOpenP"
                placeholder="请选择"
                class="el-input__diatext"
                style="width: 100%"
                @change="selectApplyCondition"
                clearable
              >
                <el-option
                  v-for="item in applications"
                  style="width: 730px"
                  :key="item.id"
                  :label="item.content"
                  :value="item"
                ></el-option>
                <div class="showtips">{{ posttips }}</div>
              </el-select>
              <el-input
                v-else
                :disabled="myFormOpenP"
                v-model="myForm.studentExamInfo.applyCondition"
                @input="inputApplyCondition"
                placeholder="输入申报条件"
              />
            </el-form-item>
          </td>
        </tr>
        <tr v-if="!myFormOpen">
          <td class="bg_text">
            <i>*</i>贯通条件
          </td>
          <td colspan="4">
            <el-form-item
              prop="studentExamInfo.passCondition"
              
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-select
                :disabled="myFormOpen"
                v-model="myForm.studentExamInfo.passCondition"
                v-if="applicationsP.length != 0"
                placeholder="请选择"
                class="el-input__diatext"
                style="width: 100%"
                @change="selectApplyConditionP"
                clearable
              >
                <el-option
                  v-for="item in applicationsP"
                  style="width: 730px"
                  :key="item.id"
                  :label="item.content"
                  :value="item"
                >
                </el-option>
                <div class="showtips">{{ posttips }}</div>
              </el-select>
              <el-input
                v-else
                :disabled="myFormOpen"
                 @input="inputApplyConditionP"
                v-model="myForm.studentExamInfo.passCondition"
                placeholder="输入贯通条件"
              />
            </el-form-item>
          </td>
        </tr>
        <tr v-else>
          <td class="bg_text">
            <i></i>贯通条件
          </td>
          <td colspan="4">
            <el-form-item
              prop="studentExamInfo.passCondition"
              
            >
              <el-select
                :disabled="myFormOpen"
                v-model="myForm.studentExamInfo.passCondition"
                v-if="applicationsP.length != 0"
                placeholder="请选择"
                class="el-input__diatext"
                style="width: 100%"
                @change="selectApplyConditionP"
                clearable
              >
                <el-option
                  v-for="item in applicationsP"
                  style="width: 730px"
                  :key="item.id"
                  :label="item.content"
                  :value="item"
                >
                </el-option>
                <div class="showtips">{{ posttips }}</div>
              </el-select>
              <el-input
                v-else
                :disabled="myFormOpen"
                 @input="inputApplyConditionP"
                v-model="myForm.studentExamInfo.passCondition"
                placeholder="输入贯通条件"
              />
            </el-form-item>
          </td>
        </tr>

        <tr>
          <td colspan="5" class="title">三、个人经历</td>
        </tr>
        <tr>
          <td rowspan="4" class="bg_text">
            教育经历（
            <br />从最高学历
            <br />填起）
          </td>
          <td class="bg_text">入学时间-毕业时间</td>
          <td class="bg_text">院校</td>
          <td class="bg_text">专业</td>
          <td class="bg_text">学习形式</td>
        </tr>
        <tr v-for="item in myForm.educateList" :key="item.key">
          <td style="width: 250px">
            <el-form-item>
              <el-date-picker
                type="date"
                placeholder="开始日期"
                style="width: 110px"
                format="yyyy-MM-dd"
                v-model="item.startTime"
                value-format="yyyy-MM-dd"
              ></el-date-picker>至
              <el-date-picker
                type="date"
                placeholder="结束日期"
                style="width: 110px"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="item.endTime"
              ></el-date-picker>
            </el-form-item>
          </td>
          <td>
            <el-form-item>
              <el-input placeholder="输入院校" v-model.trim="item.organ" />
            </el-form-item>
          </td>
          <td>
            <el-form-item>
              <el-input placeholder="输入专业" v-model.trim="item.subject" />
            </el-form-item>
          </td>
          <td>
            <el-form-item>
              <el-select v-model="item.learnType">
                <el-option :value="1" label="全日制">全日制</el-option>
                <el-option :value="2" label="非全日制">非全日制</el-option>
              </el-select>
            </el-form-item>
          </td>
        </tr>

        <tr>
          <td colspan="5" class="title">
            本人承诺从事本职业或相关职业工作
            <input
              type="text"
              style="
                border: none;
                border-bottom: 1px solid #000000;
                line-height: 30px;
                width: 70px;
                padding: 0 10px;
                text-align: center;
              "
              v-model="myForm.studentExamInfo.workingYears"
            />
            年，具体工作经历如下：
          </td>
        </tr>
        <tr>
          <td rowspan="4" class="bg_text">工作经历<br /><span class="spann">提示:请从最早的工作经历按顺序往下填写</span></td>
          <td class="bg_text">起止时间</td>
          <td class="bg_text">单位名称</td>
          <td class="bg_text">职务或岗位</td>
          <td class="bg_text">单位联系人、电话</td>
        </tr>

        <tr v-for="(item, index) in myForm.jobList" :key="item.key">
          <td style="width: 250px; background-color: #ffffff" class="bg_text">
            <i
              v-if="index == 0 && selectApplyConditions.isTrue"
              style="position: absolute; left: -21px"
            >*</i>
            <el-form-item>
              <el-date-picker
                type="date"
                placeholder="开始日期"
                v-model="item.startTime"
                style="width: 110px"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                @change="changeStarTime(index)"
              ></el-date-picker>至
              <el-date-picker
                type="date"
                placeholder="结束日期"
                style="width: 110px"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="item.endTime"
                :picker-options="
                  index == 0
                    ? pickerOptionsEnd1
                    : index == 1
                    ? pickerOptionsEnd2
                    : pickerOptionsEnd3
                "
              ></el-date-picker>
            </el-form-item>
          </td>
          <td>
            <el-form-item>
              <el-input v-model.trim="item.organ" placeholder="输入单位名称" />
            </el-form-item>
          </td>
          <td>
            <el-form-item>
              <el-input v-model.trim="item.subject" placeholder="输入职务或岗位" />
            </el-form-item>
          </td>
          <td>
            <el-form-item>
              <el-input v-model.trim="item.contacts" placeholder="输入单位联系人、电话" />
            </el-form-item>
          </td>
        </tr>
        <tr class="bottom_box">
          <td colspan="5" class="mytext-area">
            <div style="display: flex; flex-direction: column">
              <p style="font-size: 14px; line-height: 30px">
                <span style="font-weight: bold">填表声明：1.</span>
                本人知晓本职业（工种）报考条件、资格审核相关要求，承诺遵守报考的有关要求，保证本次填报的信息完整准确、教育经历、工作经历真实，如有必要愿意配合核实。如有虚假，愿意接受取消申报资格、已参加考试则被取消当次考试所有科目成绩、已获得证书则被收回、注销证书数据检索及注销证书资格的处理，并登记在诚信档案。
                <span
                  style="font-weight: bold"
                >2.</span>
                报考个人信息已经本人确认，不再更改。
              </p>
              <p style="margin-top: 20px">
                <el-checkbox
                  v-model="checkedtable"
                  style="font-size: 14px; color: #000000"
                >本人确认已阅读并明白上述条款，并受声明条款约束。</el-checkbox>
              </p>
            </div>
          </td>
        </tr>
      </table>

      <div class="btn_list">
        <el-button plain class="last_btn" @click="toStep(1)" v-if="!isChange">上一步</el-button>
        <el-button plain class="last_btn" @click="save">保存</el-button>
        <el-button type="primary" class="next_btn" @click="save(3)">下一步</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { compressAccurately } from "image-conversion"; //按需引入
import { getStudentInfo, getPlanInfo, confirmTable } from "@/util/api";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
        }
      },
      pickerOptionsEnd1: {
        disabledDate: time => {
          let beginDateVal = this.jobList[0].startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        }
      },
      pickerOptionsEnd2: {
        disabledDate: time => {
          let beginDateVal = this.jobList[1].startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        }
      },
      pickerOptionsEnd3: {
        disabledDate: time => {
          let beginDateVal = this.jobList[2].startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        }
      },
      myFormOpenP:false,
      myFormOpen:false,
      myForm: {
        student: {},
        studentExamInfo: {}
      },
      educateSelectList: [
        {
          value: 0,
          label: "小学"
        },
        {
          value: 1,
          label: "初级中学"
        },
        {
          value: 2,
          label: "普通中学"
        },
        {
          value: 3,
          label: "技工学校"
        },
        {
          value: 4,
          label: "职业高中"
        },
        {
          value: 5,
          label: "中等专科"
        },
        {
          value: 6,
          label: "大学专科"
        },
        {
          value: 7,
          label: "大学本科"
        },
        {
          value: 8,
          label: "硕士研究生"
        },
        {
          value: 9,
          label: "博士研究生"
        },

        {
          value: 11,
          label: "高技"
        },
        {
          value: 10,
          label: "其他"
        }
      ],
      planInfo: {},
      isChange: false,
      // 工作经历
      jobList: [
        {
          key: 11,
          contacts: "",
          resumeType: 2,
          endTime: "",
          organ: "", //院校
          startTime: "",
          subject: "", //专业
          time: ""
        },
        {
          key: 12,
          contacts: "",
          resumeType: 2,
          endTime: "",
          organ: "", //院校
          startTime: "",
          subject: "", //专业
          time: ""
        },
        {
          key: 13,
          contacts: "",
          resumeType: 2,
          endTime: "",
          organ: "", //院校
          startTime: "",
          subject: "", //专业
          time: ""
        }
      ],
      educateList: [
        {
          key: 21,
          resumeType: 1,
          endTime: "",
          learnType: null,
          organ: "", //院校
          startTime: "",
          subject: "", //专业
          time: ""
        },
        {
          key: 22,
          resumeType: 1,
          endTime: "",
          learnType: null,
          organ: "", //院校
          startTime: "",
          subject: "", //专业
          time: ""
        },
        {
          key: 23,
          resumeType: 1,
          endTime: "",
          learnType: null,
          organ: "", //院校
          startTime: "",
          subject: "", //专业
          time: ""
        }
      ],
      selectApplyConditions: {},
      applications: [],
      applicationsP:[],
      posttips: "",
      showQuestion: false,
      checkedtable: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
        }
      },
      pickerOptionsEnd1: {
        disabledDate: time => {
          let beginDateVal = this.jobList[0].startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        }
      },
      pickerOptionsEnd2: {
        disabledDate: time => {
          let beginDateVal = this.jobList[1].startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        }
      },
      pickerOptionsEnd3: {
        disabledDate: time => {
          let beginDateVal = this.jobList[2].startTime;
          if (beginDateVal) {
            return (
              time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000 ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        }
      }
    };
  },
  mounted() {
    this.getData();
    this.isChange = this.$route.query.isChange;
    console.log(this.isChange);
  },
  methods: {
    // 切换工作经历开始时间
    changeStarTime(i) {
      if (i == 0) {
        this.jobList[0].endTime = "";
      }
      if (i == 1) {
        this.jobList[1].endTime = "";
      }
      if (i == 2) {
        this.jobList[2].endTime = "";
      }
    },
    changEexamType(e) {
      if (e == "补考") {
        this.studentInfo.profSubject = null;
      }
    },
    toStep(i) {
      this.$emit("changeStep", i);
    },
    //   获取基本信息
    getData() {
      getStudentInfo({
        planId: this.$route.query.planId
      }).then(res => {
        console.log(res,'res123')
        this.myForm = res.data;
        if(this.myForm.studentExamInfo.applyCondition!=""){
          this.myFormOpen = true
          this.myFormOpenP = false
        }else if(this.myForm.studentExamInfo.passCondition!=""){
          this.myFormOpen = false
          this.myFormOpenP = true
        }else{
          this.myFormOpen = false
          this.myFormOpenP = false
        }
       
        this.applications = res.data.requireList;
        this.applicationsP = res.data.passConditionList;
        for (let i in res.data.requireList) {
          if (res.data.requireList[i].requireYear) {
            res.data.requireList[i].isTrue = true;
          }
        }
        for (let i in res.data.passConditionList) {
          if (res.data.passConditionList[i].requireYear) {
            res.data.passConditionList[i].isTrue = true;
          }
        }
        if (res.data.studentExamInfo) {
          this.myForm.studentExamInfo = res.data.studentExamInfo;
          // return;
        } else {
          this.myForm.studentExamInfo = {
            workingYears: null
          };
        }

        getPlanInfo({
          planId: this.$route.query.planId,
          domainUrl: window.location.hostname
        }).then(Pres => {
          this.planInfo = Pres.data;
          this.myForm.studentExamInfo.profession = this.planInfo.skillCategoryFirstName;
          this.myForm.studentExamInfo.profLevel = this.planInfo.skillCategoryThirdName;
          this.myForm.studentExamInfo.planId = this.planInfo.planId;
          if (this.myForm.educateList.length == 0) {
            this.myForm.educateList = this.educateList;
          }

          if (this.myForm.jobList.length == 0) {
            this.myForm.jobList = this.jobList;
          }
          this.myForm.studentExamInfo.planId = this.$route.query.planId;
          this.myForm.studentExamInfo.studentId = this.myForm.student.id;
        });
      });
    },
    diffDate(stData1, endData2) {
      console.log(stData1,endData2,'endData2')
      let stData = new Date(stData1).getTime();
      let endData = new Date(endData2).getTime();
      let data3 = endData - stData;
      let year = 0;
      year = parseInt(data3 / (1000 * 3600 * 24 * 365));
      return year;
    },

    dataURLtoBlob(dataURL, type) {
      var binary = atob(dataURL.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: type });
    },
    // 上传表格头像
    changeFile(res, file) {
      let that = this;
      // const isLt10M = res.size / 1024 < 2048;
      if (["image/jpeg", "image/jpg"].indexOf(res.raw.type) == -1) {
        this.$message.error("请上传jpg/jpeg图片格式");
        return false;
      }
      console.log(res.raw);
      if (res.raw.size / 1024 / 1024 <= 2) {
        return new Promise(resolve => {
          // 压缩到100KB,这里的100就是要压缩的大小,可自定义
          compressAccurately(res.raw, 2000).then(myImg => {
            console.log(
              new File([res], res.raw.name, {
                type: res.raw.type,
                lastModified: Date.now()
              })
            );
            const fr = new FileReader();
            fr.readAsDataURL(myImg);
            fr.onload = function() {
              that.myForm.studentExamInfo.avatarImageData = fr.result;
              that.$forceUpdate();
            };
            resolve(myImg);
          });
        });
      } else {
        this.$message.warning("请上传2MB及以下的图片")
        return
        let that = this;
        const fr = new FileReader();
        fr.readAsDataURL(res.raw);
        fr.onload = function() {
          that.myForm.studentExamInfo.avatarImageData = fr.result;
          that.$forceUpdate();
        };
      }
      // if (!isLt10M) {
      //   this.$message.error("上传图片不能超过500k哦!");
      //   return false;
      // }
    },
    changeinput() {},
    // 选择申报条件
    selectApplyCondition(e) {
      console.log(e);
      if(e==''){
        this.myFormOpen = false
      }else{
        this.myFormOpen = true
      }
      this.selectApplyConditions = e;
      
      this.myForm.studentExamInfo.applyCondition = e.content;
      this.myForm.studentExamInfo.requireYear = e.requireYear;
    },
    selectApplyConditionP(e) {
      if(e==''){
        this.myFormOpenP = false
      }else{
        this.myFormOpenP = true
      }
      this.myForm.studentExamInfo.passCondition = e.content;
      this.myForm.studentExamInfo.requireYear = e.requireYear;
    },
    inputApplyCondition(e){
      // console.log(e);
      if(e==''){
        this.myFormOpen = false
      }else{
        this.myFormOpen = true
      }
    },
    inputApplyConditionP(e){
      // console.log(e);
      if(e==''){
        this.myFormOpenP = false
      }else{
        this.myFormOpenP = true
      }
    },
    // 保存
    save(i) {
      let starDateArr = [],
        endDateArr = [];
      for (let i in this.jobList) {
        if (this.myForm.jobList[i].startTime) {
          starDateArr.push(this.myForm.jobList[i].startTime);
        }
        if (this.myForm.jobList[i].endTime) {
          endDateArr.push(this.myForm.jobList[i].endTime);
        }
      }
      
      let newStar = starDateArr.sort(function(a, b) {
        return a < b ? 1 : -1;
      });
      let newEnd = endDateArr.sort(function(a, b) {
        return a < b ? 1 : -1;
      });
      this.$refs["myForm"].validate(valid => {
        if (valid) {
          if (!this.myForm.studentExamInfo.avatarImageData) {
            this.$message.warning("请上传一寸彩色（白底）");
            return;
          }
          if (!this.checkedtable) {
            this.$message.warning("请勾选同意协议");
            return;
          }
          
          if (
            this.myForm.studentExamInfo.requireYear &&
            Number(this.myForm.studentExamInfo.workingYears) <
              Number(this.myForm.studentExamInfo.requireYear)
          ) {
            this.$message.warning("工作年限与申报条件年限不符合");
            return;
          }
          
          if (Number(this.myForm.studentExamInfo.requireYear || 0) <= Number(this.diffDate(newStar[newStar.length - 1], newEnd[0] || 0)
              ) ||
            Number(this.myForm.studentExamInfo.requireYear || 0) == 0
          ) {
            // if(this.myForm.studentExamInfo.profCertGainTime == null) this.myForm.studentExamInfo.profCertGainTime = ''
            // if(this.myForm.studentExamInfo.techCertGainTime == null) this.myForm.studentExamInfo.techCertGainTime = ''
            confirmTable(this.myForm).then(res => {
              // location.reload([bForceGet]) 
              // location.reload()
              if (this.$route.query.isChange) {
                this.$emit("changeStep", 2);
                return;
              }
              if (i) {
                this.$emit("changeStep", 3);
              } else {
                this.$message.success("保存成功");
              }
            });
          } else {
            this.$message.warning("工作经历年限总和与申报条件年限不符合");
          }
          // confirmTable(this.myForm).then((res) => {
          //   if (this.$route.query.isChange) {
          //     this.$emit("changeStep", 2);
          //     return;
          //   }
          //   if (i) {
          //     this.$emit("changeStep", 3);
          //   } else {
          //     this.$message.success("保存成功");
          //   }
          // });
        }
      });
    }
  }
};
</script>

<style>
.el-image-viewer__canvas img {
  width: 60%;
  height: auto;
  max-height: auto;
}
.el-select-dropdown {
  max-width: 50%;
}
.el-select-dropdown__item {
  word-break: break-all;
  word-break: break-all;
  height: auto;
  text-overflow: initial;
  overflow: initial;
  white-space: inherit;
}
.el-tag--small {
  height: auto;
}
.el-tag {
  white-space: inherit;
}
.el-input--suffix .el-input__inner {
  padding: 0 30px 0 10px;
}
.el-icon-date {
  display: none;
}
</style>
<style scoped>
.setp_2 >>> .el-form-item__error {
  z-index: 9;
}
.mytext-area >>> .el-form-item__content {
  width: 100%;
}
.setp_2 >>> .el-radio {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.my_avatar-uploader >>> .el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.my_avatar-uploader >>> .el-upload i {
  margin-top: 50px;
}
.el-input--suffix .el-input__inner {
  padding-right: 0;
}
table,
tr,
td {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.spann{
  font-size:14px
}
.my_avatar-uploader {
  width: 100%;
  height: 100%;
  height: 180px;

  p {
    position: absolute;
    bottom: 40px;
  }
}
.title {
  text-align: center;
  .icon {
    color: #2b68d9;
    margin: 0 10px;
  }
}
.setp_2 {
  table {
    width: 100%;
    margin: 30px 0;
    border-color: rgba(43, 104, 217, 0.2);
  }
  .title {
    height: 53px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
  td {
    line-height: 24px;
    text-align: center;
    height: 40px;
    font-size: 16px;
    border-color: rgba(43, 104, 217, 0.2);
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    width: 111.5px;
    height: 169.02px;
    display: block;
  }

  .bg_text {
    width: 152px;
    background: #e9effb;
    position: relative;
    i {
      color: red;
      margin: 5px;
      // position: absolute;
      // left: 5px;
      // top: 5px;
    }
  }
  .card_box {
    position: absolute;
    width: 80%;
    height: 80%;
    border-radius: 20px;
    background-color: #ffffff;
    right: -163px;
    padding: 10%;
    box-shadow: 3px -2px 20px 11px #cccccc;
    z-index: 11;
    font-size: 14px;
    top: 0;
    text-align: justify;
  }
  .bottom_box {
    height: 205px;
    td {
      padding: 20px;
      text-align: left;
      div {
        display: flex;
        // height: 100%;
      }
    }
  }
}
.btn_list {
  display: flex;
  justify-content: center;
  button {
    width: 130px;
    height: 32px;
    line-height: 32px;
    border-radius: 10px;
    padding: 0;
    line-height: 32px;
    margin: 30px;
  }
  .last_btn {
    background-color: #fff;
    border: 1px solid #2b68d9;
    color: #2b68d9;
  }
  .next_btn {
    background: #2b68d9;
  }
}
</style>
